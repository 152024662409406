<template>
  <div class="py-5 custom-height">
    <div class="w-100 d-flex text-group">
      <span class="text-center text-item">Selected Indicator has only National Data </span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.text-item {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 700;
}
.custom-height {
  height: 400px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-group {
  display: flex;
  justify-content: center;
}
</style>
