var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 index-app"},[(
      Object.entries(_vm.configObject).length > 0 &&
      _vm.isAdvanced === false &&
      _vm.isGIS === false &&
      _vm.loading === false
    )?_c('MSDAT'):_vm._e(),(
      Object.entries(_vm.configObject).length > 0 &&
      _vm.isAdvanced === true &&
      _vm.isGIS === false &&
      _vm.loading === false
    )?_c('AdvanceMSDAT',{attrs:{"indicators":_vm.configObject.indicators,"dataSources":_vm.configObject.dataSources,"defaultIndicators":_vm.configObject.defaultIndicators,"initialIndicator":_vm.configObject.initialIndicator,"initialDataSource":_vm.configObject.initialDataSource,"initialLocation":_vm.configObject.initialLocation,"showTableRelatedIndicator":_vm.configObject.showTableRelatedIndicator != undefined
        ? _vm.configObject.showTableRelatedIndicator
        : true}}):_vm._e(),(
      Object.entries(_vm.configObject).length > 0 &&
      _vm.isGIS === true &&
      _vm.isAdvanced === false &&
      _vm.loading === false
    )?_c('GisMSDAT',{attrs:{"indicators":_vm.configObject.indicators,"dataSources":_vm.configObject.dataSources,"defaultIndicators":_vm.configObject.defaultIndicators,"initialIndicator":_vm.configObject.initialIndicator,"initialDataSource":_vm.configObject.initialDataSource,"initialLocation":_vm.configObject.initialLocation,"showTableRelatedIndicator":_vm.configObject.showTableRelatedIndicator != undefined
        ? _vm.configObject.showTableRelatedIndicator
        : true}}):_vm._e(),(_vm.showClearDataModal)?_c('ClearDBModal',{staticStyle:{"z-index":"1500"}}):_vm._e(),(_vm.$route.query.prev)?_c('div',{staticClass:"preview"},[_c('b-button',{staticStyle:{"font-size":"1.5rem"},attrs:{"size":"lg","variant":"info"},on:{"click":function($event){return _vm.$router.push('/custom/requests')}}},[_vm._v("Back to Requests")])],1):_vm._e(),_c('NewsLetter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }