export default [
  {
    name: 'Health_Outcomes_and_Service_Coverage',
    title: 'Health Outcomes and Service Coverage',
    indicators: [
      1, 2, 3, 4, 5, 6, 7, 8,
      9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
      28, 29, 30, 31, 32, 33, 215, 527,
    ],
    defaultIndicators: [7, 6, 5],
    dataSources: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 18, 26, 33],
    initialIndicator: 7,
    initialDataSource: 6,
    initialLocation: 1,
  },
  {
    name: 'Health_Facility',
    title: 'Health Facility',
    indicators: [
      34, 35, 36, 37, 38, 45, 46, 47, 48, 49, 50, 51, 52, 54, 55, 56, 57, 58, 59, 60, 61, 284, 285, 286, 287, 288, 289, 290, 291, 292, 297, 298, 299, 300, 301, 314, 328, 340, 341, 342,
      343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 360, 361,
      365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 385, 386, 388,
      389, 390, 391, 392, 393, 394, 395],
    defaultIndicators: [51, 50, 49], // preferable and indicator ID and related indicators,
    // dataSources: [17, 2, 1],
    dataSources: [17],
    initialIndicator: 51,
    initialDataSource: 17,
    initialLocation: 1,
  },
  {
    name: 'Health_Financing',
    title: 'Health Financing',
    indicators: [
      95, 96, 97, 98, 99, 100, 101, 102, 219, 220, 221, 222, 223, 224, 225, 264,
      265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 329, 330, 331, 332, 333, 334,
      335, 336, 337, 338, 380, 381, 382, 383, 384,
    ],
    defaultIndicators: [98, 265, 264], // an indicator ID and related indicators,
    dataSources: [7, 9, 14, 17, 25],
    initialIndicator: 98,
    initialDataSource: 14,
    initialLocation: 1,
  },
  {
    name: 'Health_Workforce',
    title: 'Health Workforce',
    indicators: [
      103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116,
      117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
      136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154,
      155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173,
      174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192,
      193, 194, 195, 226, 227, 228, 229, 230, 231, 233, 234, 235, 236, 237, 238, 239, 240, 241,
      242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260,
      261, 262, 278, 279, 280, 281, 282, 283],
    defaultIndicators: [255, 174, 235], // an indicator ID and related indicators,
    dataSources: [24, 25],
    initialIndicator: 255,
    initialDataSource: 24,
    initialLocation: 1,
  },
  {
    name: 'Demographics',
    title: 'Demographics',
    showTableRelatedIndicator: false,
    // add 65 for checks
    indicators: [
      63, 1, 30, 31, 32, 64, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
      84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 396, 397, 398, 399, 400,
    ],
    // defaultIndicators: [63, 64, 65], // an indicator ID and related indicators,
    defaultIndicators: [63],
    dataSources: [1, 2, 3, 4, 6, 7, 8, 9, 15, 18, 19, 20, 21, 22, 23, 26, 27, 28, 29, 33, 36],
    initialIndicator: 63,
    initialDataSource: 19,
    initialLocation: 1,
  },
  {
    name: 'Health_Service_Access',
    title: 'Health Service Access',
    indicators: [
      39, 40, 41, 42, 44, 53, 62, 293, 294, 295, 296, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327, 362, 363, 364,
    ],
    defaultIndicators: [39, 48, 37], // an indicator ID and related indicators,
    dataSources: [17],
    initialIndicator: 39,
    initialDataSource: 17,
    initialLocation: 1,
  },

  {
    name: 'Advanced_Analytics',
    title: 'Advanced Analytics',
    indicators: [
      1, 30, 31, 32, 63, 64, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
      84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 396, 397, 398, 399, 400,
      55, 56, 57, 58, 95, 96, 97, 98, 99, 100, 101, 102, 219, 220, 221, 222, 223, 224, 225, 264,
      265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 329, 330, 331, 332, 333, 334,
      335, 336, 337, 338, 380, 381, 382, 383, 384, 39, 40, 41, 42, 43, 44, 62, 293, 294, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313,
      320, 326, 362, 363, 364, 1, 30, 31, 32, 63, 64, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
      84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 396, 397, 398, 399, 400, 34, 35, 36, 37, 52, 53, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116,
      117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
      136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154,
      155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173,
      174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192,
      193, 194, 195, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241,
      242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260,
      261, 262, 263, 278, 279, 280, 281, 282, 283, 315, 321, 322, 323, 324, 325,
    ],
    defaultIndicators: [266, 265, 264, 63], // an indicator ID and related indicators,
    dataSources: [14, 7, 9, 25, 17],
    initialIndicator: 63,
    initialDataSource: 9,
    initialLocation: 1,
  },
  {
    name: 'GIS_Mapping_Dashboard',
    title: 'GIS Mapping',
    indicators: [
      39, 40, 41, 42, 44, 53, 62, 293, 294, 295, 296, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327, 362, 363, 364,
    ],
    defaultIndicators: [39, 48], // an indicator ID and related indicators,
    dataSources: [17],
    initialIndicator: 39,
    initialDataSource: 17,
    initialLocation: 1,
  },
];
